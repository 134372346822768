import i18n from "./i18n.js";
import sha256 from 'crypto-js/sha256'
import {categoryIdImgDev, categoryIdImgProd} from "../config/common.js";

const nonce = 'hello_onestep'

const SpaceType = {
    '所有空間類型': -1,
    '商務/聚會/課程': 0,
    '共享座位': 1,
    '獨立辦公室': 2,
    '會議室': 3,
    '活動空間': 4,
    '電話亭': 5,
    '教室': 6,
    '廚房': 7,
    '攝影棚': 8,
    '桌遊': 9,
    '其他': 10
};


// 用空間名稱去找 SpaceType
export function findSpaceTypeIndex(targetType) {
    const index = SpaceType[targetType];
    return index !== undefined ? index : -1;
}

// 用 SpaceType 去找空間名稱
export function findSpaceTypeName(targetIndex) {
    targetIndex = Number(targetIndex)
    for (const spaceTypeName in SpaceType) {
        if (SpaceType[spaceTypeName] === targetIndex) {
            return spaceTypeName;
        }
    }
    return '所有空間類型';
}

export function parseSpaceType(spaceType) {
    switch (spaceType?.toString()) {
        default:
        case '-1':
            return i18n.global.tm('type_dropdown')[0]
        case '0':
            return i18n.global.tm('type_dropdown')[1]
        case '1':
            return i18n.global.tm('type_dropdown')[2]
        case '2':
            return i18n.global.tm('type_dropdown')[3]
        case '3':
            return i18n.global.tm('type_dropdown')[4]
        case '4':
            return i18n.global.tm('type_dropdown')[5]
        case '5':
            return i18n.global.tm('type_dropdown')[6]
        case '6':
            return i18n.global.tm('type_dropdown')[7]
        case '7':
            return i18n.global.tm('type_dropdown')[8]
        case '8':
            return i18n.global.tm('type_dropdown')[9]
        case '9':
            return i18n.global.tm('type_dropdown')[10]
        case '10':
            return i18n.global.tm('type_dropdown')[11]
    }
}

/// 出租方式: 0=時租, 1=日租, 2=月租, 3=年租 4=時段租
export function parseRentType(rentType) {
    switch (rentType.toString()) {
        default:
        case '0':
            return i18n.global.t('common.hour_rent')
        case '1':
            return i18n.global.t('common.day_rent')
        case '2':
            return i18n.global.t('common.month_rent')
        case '3':
            return i18n.global.t('common.year_rent')
        case '4':
            return i18n.global.t('common.period_rent')
    }
}

/// 出租方式: 0=小時, 1=天
export function parseRentUnit(rentType) {
    if (rentType || rentType.toString() === '0') {
        switch (rentType.toString()) {
            default:
            case '0':
                return i18n.global.t('common.hour_rent_unit')
            case '1':
                return i18n.global.t('common.day_rent_unit')
            case '4':
                return i18n.global.t('common.period_rent_unit')
        }
    }
}

export function parseNumber(num) {
    if (num) {
        return num.toString().replace(/\B(?=(\d{3})+(?!\d))/g, ',')
    } else {
        return num
    }
}


export function parseRentPrice(rent) {
    return rent.map(rent => {
        if (rent.type !== 4) {
            return `NT$${parseNumber(rent.price)} / ${parseRentUnit(rent.type)}`
        } else {
            return i18n.global.t('common.period_rent')
        }
    }).join('、')
}

export function parseFormatLowestRent(rents) {
    if (!Array.isArray(rents) || rents.length === 0) {
        return '';
    }

    // 對租金數據進行排序，並取得最低價格的租金
    // const lowestRent = rents.sort((a, b) => a.price - b.price)[0];
    const lowestRent = rents.reduce((accumulator, current) => {

        return (Number(accumulator.price) < Number(current.price)) ? accumulator : current;
    });

    // 時段租直接返回文字
    if (lowestRent.type === 4) {
        return {
            // price: i18n.global.t('common.period_rent'),
            price: `NT$${parseNumber(lowestRent.period_detail[0].price)}~`,
            type: `/${parseRentUnit(lowestRent.type)}`
        }
    }

    // 使用模板字符串和解構賦值來格式化返回的租金對象
    const {price, type} = lowestRent;
    return {
        price: `NT$${parseNumber(price)}`,
        type: `/${parseRentUnit(type)}`
    };
}

export function parseRentMRT(traffic) {
    return traffic.map(traffic => traffic.mrt_station + "站" + traffic.mrt_exit + "號出口").join(' • ')
}

export function parseTrafficsMRT(traffic) {
    return traffic.map(traffic => `${traffic.mrt_station}站 徒步${traffic.walking_time}`).join(' • ')
}

export function parseSpaceTypeName(spaceTypeList) {
    if (spaceTypeList) {
        return spaceTypeList.map(item => item.name).join(', ');
    }
    return ''

}

export function parseRentTypeArray(rent) {
    return rent.map(rent => parseRentType(rent.type))
}


export function orderHourDate(date) {
    const dayList = ['日', '一', '二', '三', '四', '五', '六']
    const day = new Date(date).getDay()
    const formattedDate = formatDate(date)
    const dateWithDay = `${formattedDate}(${dayList[day]})`

    // date = `${new Date(date).format('yyyy/MM/dd')}(${dayList[day]})`

    return dateWithDay
}

// 日期格式化 todo 看要不要export
function formatDate(date) {
    const d = new Date(date)
    const year = d.getUTCFullYear()
    const month = String(d.getUTCMonth() + 1).padStart(2, '0')
    const day = String(d.getUTCDate()).padStart(2, '0')
    return `${year}/${month}/${day}`
}

export function orderExtractTimePart(dateTime) {
    /// 24:00:00 轉 23:59:59
    if (dateTime.endsWith('24:00:00')) {
        dateTime = dateTime.substring(0, 10) + ' 23:59:59'
    }
    const date = new Date(dateTime);
    return `${String(date.getHours()).padStart(2, '0')}:${String(date.getMinutes()).padStart(2, '0')}`;
}


//日期格式
// const startDate = '2023/11/25 00:00:00';
// const endDate = '2023/11/26 23:59:59';
export function calculateDateDifference(startDate, endDate) {

    /// 23:59:59 轉 24:00:00
    if (endDate.endsWith('23:59:59')) {
        endDate = endDate.substring(0, 10) + ' 24:00:00'
    }

    // 將日期字符串轉換為日期對象
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    const isSameDay = startDate.substring(0, 10) === endDate.substring(0, 10);

    const isMidnightToMidnight = startDateTime.getHours() === 0 &&
        startDateTime.getMinutes() === 0 &&
        startDateTime.getSeconds() === 0 &&
        endDateTime.getHours() === 24 &&
        endDateTime.getMinutes() === 0 &&
        endDateTime.getSeconds() === 0;

    if (isSameDay && isMidnightToMidnight) {
        // 同一天，開始時間是 '00:00:00'，結束時間是 '23:59:59'，視為一天
        return {daysDifference: 1, hoursDifference: 0};
    }

    if (isSameDay) {
        // 同一天，計算小時差異
        const hoursDifference = (endDateTime - startDateTime) / (1000 * 60 * 60);

        if (Number(hoursDifference) === 24) {
            return {daysDifference: 1, hoursDifference: 0};
        }
        return {daysDifference: 0, hoursDifference};
    } else {
        // 不同天，計算天數差異
        // 將日期時間設置為每天的開始和結束
        startDateTime.setHours(0, 0, 0, 0);
        endDateTime.setHours(23, 59, 59, 999);

        const daysDifference = Math.floor((endDateTime - startDateTime) / (1000 * 60 * 60 * 24));

        return {daysDifference, hoursDifference: 0};
    }
}

export function OOldcalculateDateDifference(startDate, endDate) {
    const startDateTime = new Date(startDate);
    const endDateTime = new Date(endDate);
    const isSameDay = startDateTime.toDateString() === endDateTime.toDateString();

    if (isSameDay && startDateTime.getHours() === 0 && endDateTime.getHours() === 23 && endDateTime.getMinutes() === 59 && endDateTime.getSeconds() === 59) {
        // 同一天，開始時間是 '00:00:00'，結束時間是 '23:59:59'，視為一天
        return {daysDifference: 1, hoursDifference: 0};
    } else {
        //
        const hoursDifference = isSameDay ? Math.ceil(endDateTime - startDateTime) / (1000 * 60 * 60) : 0;
        const daysDifference = Math.floor((endDateTime.setHours(0, 0, 0, 0) - startDateTime.setHours(0, 0, 0, 0)) / (1000 * 60 * 60 * 24));

        return {daysDifference, hoursDifference};
    }
}


/// 付款狀態: 0=失敗, 1=成功, 2=未付款, 3=待確認, 4=退款
export function parsePaymentStatus(paymentStatus) {
    switch (paymentStatus.toString()) {
        default:
        case '0':
            return i18n.global.t('order.fail')
        case '1':
            return i18n.global.t('order.success')
        case '2':
            return i18n.global.t('order.not_pay')
        case '3':
            return i18n.global.t('order.pending')
        case '4':
            return i18n.global.t('order.refunded_already')
    }
}

// 判斷是不是今天
export function isToday(someDate) {
    someDate = new Date(someDate)
    const today = new Date()
    // 將時間部分設為 0，只比較日期
    someDate.setHours(0, 0, 0, 0);
    today.setHours(0, 0, 0, 0);

    return someDate.getTime() === today.getTime();
}

// hash password
export function hashPassword(password) {
    return sha256(password).toString()
}

// 將時間調整到最接近的30分或整點
export function adjustTimeToNearest30(time) {
    const hours = parseInt(time.slice(0, 2));
    const minutes = parseInt(time.slice(3, 5));

    // 調整到最接近的30分或整點
    let adjustedHours = hours;
    let adjustedMinutes = Math.round(minutes / 30) * 30;

    if (adjustedMinutes === 60) {
        adjustedMinutes = 0;
        adjustedHours += 1;
    }

    return (
        adjustedHours.toString().padStart(2, '0') +
        ':' +
        adjustedMinutes.toString().padStart(2, '0') +
        ':00'
    );
}

export function parseDayOfWeek(num) {
    switch (num) {
        case 0:
            return i18n.global.t('common.week0')
        case 1:
            return i18n.global.t('common.week1')
        case 2:
            return i18n.global.t('common.week2')
        case 3:
            return i18n.global.t('common.week3')
        case 4:
            return i18n.global.t('common.week4')
        case 5:
            return i18n.global.t('common.week5')
        case 6:
            return i18n.global.t('common.week6')
        default:
            return num.toString()
    }
}

export function getElementByDate(array) {
    const today = new Date();
    const day = today.getDate();
    const index = day % array.length;
    return array[index];
}

export function getCategoryImageFileName(id) {
    const env = import.meta.env.VITE_ENV;
    let envMapping;
    switch (env) {
        case 'dev':
            envMapping = categoryIdImgDev;
            break;
        case 'stage':
            envMapping = categoryIdImgProd;
            break;
        case 'prod':
            envMapping = categoryIdImgProd;
            break;
        default:
            return 'Invalid environment';
    }

    return envMapping[id] || 'ID not found';
}

// 判斷日期是否在今天之後的幾天內，算可不可以退款
export function isWithinDaysFromToday(date, days) {
    const today = new Date();
    const targetDate = new Date(date);
    const differenceInMilliseconds = targetDate - today;
    const differenceInDays = differenceInMilliseconds / (1000 * 60 * 60 * 24);

    return differenceInDays <= days;
}


export function parseSortDir(value) {

    if (!value) {
        return {
            sort: 'created_date',
            dir: 1
        };
    }

    const [sortKey, dir] = value.split('-');
    const dirNum = Number(dir);

    return {
        sort: sortKey,
        dir: dirNum
    };
}

export function serializeGeoSearch(val) {
    if (
        val &&
        val.longitude !== 0 &&
        val.latitude !== 0 &&
        val.width !== 0 &&
        val.height !== 0
    ) {
        // 將對象序列化為字串，並使用 encodeURIComponent 進行 URL 編碼
        const serialized = `${val.latitude},${val.longitude},${val.width},${val.height}`;
        return encodeURIComponent(serialized); // 編碼後返回
    }
    return null; // 若有一個值為 0，返回 null
}

export // 反序列化函數：將字串轉回為對象
function deserializeGeoSearch(val) {
    if (val) {
        // 使用 decodeURIComponent 解碼 URL 編碼的字串
        const decoded = decodeURIComponent(val);
        // 將解碼後的字串以逗號分隔，轉換回對象
        const [latitude, longitude, width, height] = decoded.split(',');
        return {
            latitude: parseFloat(latitude),
            longitude: parseFloat(longitude),
            width: parseFloat(width),
            height: parseFloat(height)
        };
    }
    return null; // 預設回傳 null
}
