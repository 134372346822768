import revive_payload_client_yMIBEf3MVX from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_L4N3bvoFAn from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_Cl2VK2EUHM from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import _0_siteConfig_GKsIZqIsoP from "/app/node_modules/.pnpm/nuxt-site-config@2.2.15_magicast@0.3.4_rollup@4.18.0_vite@5.3.2_@types+node@20.14.9_sass@1.77_otf7qfvmlnm25gdl2q27kpfu4e/node_modules/nuxt-site-config/dist/runtime/nuxt/plugins/0.siteConfig.js";
import payload_client_yOSZrQNmos from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/app/plugins/payload.client.js";
import check_outdated_build_client_y7BvE2GG85 from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import plugin_vue3_98nHbB4kzE from "/app/node_modules/.pnpm/@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typescript@5.5.3_vue@3.5.4_typescript@5.5.3_/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.mjs";
import components_plugin_KR1HBZs4kY from "/app/.nuxt/components.plugin.mjs";
import prefetch_client_GkUg6Om1IO from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import plugin_client_VPbSZtETO2 from "/app/node_modules/.pnpm/nuxt-meta-pixel@2.0.2_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-meta-pixel/dist/runtime/plugin.client.mjs";
import plugin_client_NtT69Ip8tr from "/app/node_modules/.pnpm/nuxt-gtag@2.0.7_magicast@0.3.4_rollup@4.18.0/node_modules/nuxt-gtag/dist/runtime/plugin.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/app/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/app/.nuxt/element-plus-injection.plugin.mjs";
import plugin_dBLwxDLlTU from "/app/node_modules/.pnpm/@nuxtjs+device@3.1.1_magicast@0.3.4_rollup@4.18.0/node_modules/@nuxtjs/device/dist/runtime/plugin.mjs";
import i18n_4TInMr2wkD from "/app/node_modules/.pnpm/@nuxtjs+i18n@8.3.1_magicast@0.3.4_rollup@4.18.0_vue@3.5.4_typescript@5.5.3_/node_modules/@nuxtjs/i18n/dist/runtime/plugins/i18n.mjs";
import chunk_reload_client_Au1pIuDe5Y from "/app/node_modules/.pnpm/nuxt@3.12.2_@opentelemetry+api@1.9.0_@parcel+watcher@2.4.1_@types+node@20.14.9_encoding@0.1.1_vjysjksgobfzzmouuwb753feiu/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_DxcDTUAl4H from "/app/node_modules/.pnpm/@pinia-plugin-persistedstate+nuxt@1.2.1_@pinia+nuxt@0.5.1_magicast@0.3.4_rollup@4.18.0_typesc_nkz4jx2lqohgbyy44cckvf2rte/node_modules/@pinia-plugin-persistedstate/nuxt/dist/runtime/plugin.js";
import google_maps_isOUeleMUR from "/app/plugins/google-maps.js";
import analytics_SVnwK7EMrr from "/app/plugins/analytics.ts";
import element_plus_HUzmwi0JYR from "/app/plugins/element-plus.ts";
import svgicon_client_9ZXHQx4ITY from "/app/plugins/svgicon.client.ts";
export default [
  revive_payload_client_yMIBEf3MVX,
  unhead_L4N3bvoFAn,
  router_Cl2VK2EUHM,
  _0_siteConfig_GKsIZqIsoP,
  payload_client_yOSZrQNmos,
  check_outdated_build_client_y7BvE2GG85,
  plugin_vue3_98nHbB4kzE,
  components_plugin_KR1HBZs4kY,
  prefetch_client_GkUg6Om1IO,
  plugin_client_VPbSZtETO2,
  plugin_client_NtT69Ip8tr,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  plugin_dBLwxDLlTU,
  i18n_4TInMr2wkD,
  chunk_reload_client_Au1pIuDe5Y,
  plugin_DxcDTUAl4H,
  google_maps_isOUeleMUR,
  analytics_SVnwK7EMrr,
  element_plus_HUzmwi0JYR,
  svgicon_client_9ZXHQx4ITY
]