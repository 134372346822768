import {defineStore} from 'pinia'

const getSelectedList = (list, Key) => list.filter(item => item.selected).map(item => item[Key]);
const clearSelection = (list) => list.forEach(item => item.selected = false);

export const useFilterStore = defineStore('filter', {
    state: () => ({
        categoryTagList:[],
        tagList: [],
        equipmentList: [],
        serviceList: [],
    }),
    getters: {
        getCategoryTagList:(state)=> {
            return state.categoryTagList
        },
        getTagList: (state) => {
            return state.tagList
        },
        getEquipmentList: (state) => {
            return state.equipmentList
        },
        getServiceList: (state) => {
            return state.serviceList
        },

        getSelectedCategoryTag: (state) => {
            return getSelectedList(state.categoryTagList, 'id');
        },
        getSelectedTag: (state) => {
            return getSelectedList(state.tagList, 'id');
        },
        getSelectedEquipment: (state) => {
            return getSelectedList(state.equipmentList, 'equipment_id');
        },
        getSelectedService: (state) => {
            return getSelectedList(state.serviceList, 'service_id');
        },

        getSelectedCategoryTagName: (state) => {
            return getSelectedList(state.categoryTagList, 'name');
        },
        getSelectedTagName: (state) => {
            return getSelectedList(state.tagList, 'name');
        },
        getSelectedEquipmentName: (state) => {
            return getSelectedList(state.equipmentList, 'equipment_name');
        },
        getSelectedServiceName: (state) => {
            return getSelectedList(state.serviceList, 'service_name');
        },

        getFilterSelectedTotal: (state) => {
            return state.getSelectedCategoryTag.length+state.getSelectedTag.length + state.getSelectedEquipment.length + state.getSelectedService.length
        },
        getFilterSelected: (state) => {
            return {
                categoryTagList:state.getSelectedCategoryTag(),
                tagList: state.getSelectedTag(),
                equipmentList: state.getSelectedEquipment(),
                serviceList: state.getSelectedService(),
            }
        }
    },
    actions: {
        setCategoryTagList(value) {
            this.categoryTagList = value
        },
        setTagList(value) {
            this.tagList = value
        },
        setEquipmentList(value) {
            this.equipmentList = value
        },
        setServiceList(value) {
            this.serviceList = value
        },
        setFilter(value) {
            this.categoryTagList=value.categoryTagList
            this.tagList = value.tagList
            this.equipmentList = value.equipmentList
            this.serviceList = value.serviceList
        },

        clearCategoryTagSelected() {
            clearSelection(this.categoryTagList);
        },
        clearTagSelected() {
            clearSelection(this.tagList);
        },
        clearEquipmentSelected() {
            clearSelection(this.equipmentList);
        },
        clearServiceSelected() {
            clearSelection(this.serviceList);
        },
        clearFilterSelected() {
            clearSelection(this.categoryTagList);
            clearSelection(this.tagList);
            clearSelection(this.equipmentList);
            clearSelection(this.serviceList);
        }
    },
    /* persist: {
         enabled: true,
         strategies: [
             {
                 key: 'filter', // key可以自己定义，不填的话默认就是这个store的ID
                 storage: localStorage, // storage可以填sessionStorage，localStorage
                 // paths: ['account', 'userID','token']  // paths是需要存储的字段，不填就是全部
             },
         ],
     }*/
})

